<script>
  import {
    GridContainer,
    GridColumn,
    Header,
    Text,
    ActionText,
    ContentHandler,
    Separator,
    WidgetHeader,
    WidgetFooter,
  } from '@qlean/ui-kit-web-svelte'
  import classNames from 'classnames/bind'

  import css from './style.scss'

  const cN = classNames.bind(css)

  export let config

  const { title, titleTag, subTitle, paragraphs, withTopMargin, withBottomMargin } = config
</script>

<WidgetHeader {title} {titleTag} {subTitle} withMargin={withTopMargin} />
<GridContainer class={cN('texts')}>
  {#each paragraphs as { title, text }, idx}
    <GridColumn widthMiddle={4} class={cN('texts__unit', {'texts__unit--last': idx === paragraphs.length - 1})}>
      <Separator key={15} size={Separator.SIZES.SMALL} />
      <ActionText>{`${idx + 1}. ${title}`}</ActionText>
      <Separator key={5} size={Separator.SIZES.SMALL} />
      <ContentHandler
        baseText={{ Component: Text, props: { class: cN('texts__unit-content') } }}
        content={text} />
      <Separator key={15} size={Separator.SIZES.SMALL} />
    </GridColumn>
  {/each}
</GridContainer>
<WidgetFooter withMargin={withBottomMargin} />
