<script>
  import {
    GridContainer,
    GridColumn,
    ActionText,
    Text,
    ButtonTertiary,
    ContentHandler,
    Separator,
    WidgetHeader,
    WidgetFooter,
  } from '@qlean/ui-kit-web-svelte'
  import classNames from 'classnames/bind'

  import css from './style.scss'

  const cN = classNames.bind(css)

  export let config

  const { title, titleTag, subTitle, paragraphs, button, withTopMargin, withBottomMargin } = config
</script>

<WidgetHeader {title} {titleTag} {subTitle} withMargin={withTopMargin} />
<GridContainer class={cN('texts')}>
  {#each paragraphs as { title, text }, idx}
    <GridColumn widthMiddle={4} class={cN('texts__unit')}>
      <div class={cN('texts__unit-title')}>
        <ContentHandler baseText={{ Component: ActionText }} content={title} />
        <Separator key={10} size={Separator.SIZES.SMALL} />
      </div>
      <Separator key={5} size={Separator.SIZES.SMALL} />
      <ContentHandler baseText={{ Component: Text }} content={text} />
    </GridColumn>
  {/each}

  {#if button !== undefined}
    <GridColumn class={cN('texts__button-container')}>
      <Separator key={30} size={Separator.SIZES.SMALL} />
      <ButtonTertiary
        width={ButtonTertiary.WIDTHS.MEDIUM}
        rounded
        isLink
        target="_blank"
        href={button.href}>
        {button.text}
      </ButtonTertiary>
    </GridColumn>
  {/if}
</GridContainer>

<WidgetFooter withMargin={withBottomMargin} />
