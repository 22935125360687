<script>
  import Texts0 from './Type0'
  import Texts1 from './Type1'
  import Texts2 from './Type2'
  import Texts3 from './Type3'
  import Texts4 from './Type4'
  import Texts5 from './Type5'

  const components = [Texts0, Texts1, Texts2, Texts3, Texts4, Texts5]

  export let key
</script>

<svelte:component this={components[key]} {...$$restProps} />
