<script>
  import {
    GridContainer,
    GridColumn,
    Header,
    Text,
    ButtonSecondary,
    ContentHandler,
    Separator,
    Icon,
    WidgetHeader,
    WidgetFooter,
  } from '@qlean/ui-kit-web-svelte'
  import classNames from 'classnames/bind'

  import css from './style.scss'

  const cN = classNames.bind(css)

  export let config
  export let columnsAmount = 3

  const { title, titleTag, subTitle, paragraphs, button, withTopMargin, withBottomMargin } = config

  function getRowsAmount(paragraphsAmount, columnAmount) {
    const amount = paragraphsAmount / columnAmount

    if (Math.round(amount) !== amount) {
      // FIXME: костыль для отображения 5 параграфов
      return 2

      throw new Error('Paragraphs amount and columns amount are not consistent')
    }

    return amount
  }

  $: rowsAmount = getRowsAmount(paragraphs.length, columnsAmount)
</script>

{@html Icon.spriteContent}

<WidgetHeader {title} {titleTag} {subTitle} withMargin={withTopMargin} />
<GridContainer class={cN('texts')}>
  {#each paragraphs as { text, iconName }, idx}
    <GridColumn
      widthMiddle={12 / columnsAmount}
      class={cN('texts__unit', {
        'texts__unit--last-in-row': idx === paragraphs.length - 1,
        'texts__unit--last-in-column': idx >= (rowsAmount - 1) * columnsAmount,
      })}>
      <Separator key={15} size={Separator.SIZES.SMALL} />
      <div class={cN('texts__content')}>
        <ContentHandler baseText={{ Component: Text }} content={text} />
        {#if iconName !== undefined}
          <Icon name={iconName} key={Icon.KEYS.MEDIUM} class={cN('texts__icon')} />
        {/if}
      </div>
      <Separator key={15} size={Separator.SIZES.SMALL} />
    </GridColumn>
  {/each}

  {#if button !== undefined}
    <GridColumn class={cN('texts__button-container')}>
      <Separator key={30} size={Separator.SIZES.SMALL} />
      <ButtonSecondary width={ButtonSecondary.WIDTHS.MEDIUM} rounded isLink href={button.href}>
        {button.text}
      </ButtonSecondary>
    </GridColumn>
  {/if}
</GridContainer>

<WidgetFooter withMargin={withBottomMargin} />
