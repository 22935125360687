<script>
  import {
    GridContainer,
    GridColumn,
    Caption,
    ContentHandler,
    Separator,
    Icon,
    Link,
    WidgetHeader,
    WidgetFooter,
  } from '@qlean/ui-kit-web-svelte'
  import classNames from 'classnames/bind'

  import css from './style.scss'

  const cN = classNames.bind(css)

  export let config

  const { list, title, titleTag, subTitle, withTopMargin, withBottomMargin } = config

  const NUM_OF_CELL_IN_ROW = 4

  const rows = list.reduce((acc, unit, idx) => {
    const rowIdx = Math.floor(idx / NUM_OF_CELL_IN_ROW)

    acc[rowIdx] = acc[rowIdx] || []

    acc[rowIdx].push(unit)

    return acc
  }, [])


  const linksCommonProps = {
    target: '_blank',
    rank: Link.RANKS.THEME_PRIMARY,
    backdropColor: Link.BACKDROPS.LIGHT,
  }

  const linksUnits = list.map(({ links }) => {
    if (links === undefined) {
      return undefined
    }

    return Object.entries(links).reduce((nextUnits, [key, { href, text }]) => {
      return {
        ...nextUnits,
        [key]: {
          props: { href },
          text,
        },
      }
    }, {})
  })
</script>

{@html Icon.spriteContent}

<WidgetHeader {title} {titleTag} {subTitle} withMargin={withTopMargin} />
<GridContainer class={cN('texts')}>
  <GridColumn class={cN('texts__container')}>
    {#each rows as row, rowIdx}
      <div class={cN('texts__units-row')}>
        {#each row as { title, text, iconName }, cellIdx}
          <div class={cN('texts__unit')}>
            <Icon name={iconName} key={25} />
            <Separator key={15} size={Separator.SIZES.SMALL} />
            <Caption size={Caption.SIZES.STRONG}>{title}</Caption>
            <Separator key={5} size={Separator.SIZES.SMALL} />
            <ContentHandler
              baseText={{ Component: Caption, props: { size: Caption.SIZES.LARGE, class: cN('texts__unit-content') } }}
              links={{ Component: Link, props: linksCommonProps, units: linksUnits[rowIdx * NUM_OF_CELL_IN_ROW + cellIdx] }}
              content={text} />
          </div>
        {/each}
      </div>
    {/each}
  </GridColumn>
</GridContainer>
<WidgetFooter withMargin={withBottomMargin} />
