<script>
  import {
    GridContainer,
    GridColumn,
    Text,
    Header,
    Link,
    Icon,
    Separator,
    WidgetHeader,
    WidgetFooter,
  } from '@qlean/ui-kit-web-svelte'
  import classNames from 'classnames/bind'

  import css from './style.scss'

  const cN = classNames.bind(css)

  export let config

  const {
    title,
    text,
    titleTag,
    subTitle,
    iconName,
    link,
    withTopMargin,
    withBottomMargin,
  } = config
</script>

{@html Icon.spriteContent}

<WidgetHeader {title} {titleTag} {subTitle} withMargin={withTopMargin} />
<GridContainer class={cN('texts')}>
  <GridColumn width={0} widthMiddle={1} />
  <GridColumn width={12} widthMiddle={10} class={cN('texts__content')}>
    <Separator key={30} size={Separator.SIZES.SMALL} />
    <Icon name={iconName} key={Icon.KEYS.LARGE} />
    <Separator key={30} size={Separator.SIZES.SMALLEST} />
    <Header size={Header.SIZES.LIGHT}>{text.title}</Header>
    <Separator key={10} size={Separator.SIZES.MEDIUM} />
    <Text size={Text.SIZES.LARGE} class={cN('texts__description')}>{text.description}</Text>
    {#if link}
      <Separator key={20} size={Separator.SIZES.SMALL} />
      <Link rank={Link.RANKS.THEME_PRIMARY} backdropColor={Link.BACKDROPS.LIGHT} href={link.href}>
        {link.text}
      </Link>
    {/if}
    <Separator key={30} size={Separator.SIZES.SMALL} />
    <GridColumn width={1} widthMiddle={1} />
  </GridColumn>
  <GridColumn width={0} widthMiddle={1} />
</GridContainer>
<WidgetFooter withMargin={withBottomMargin} />
