<script>
  import {
    GridContainer,
    GridColumn,
    Text,
    ActionText,
    Header,
    Link,
    Separator,
    WidgetHeader,
    WidgetFooter,
  } from '@qlean/ui-kit-web-svelte'
  import classNames from 'classnames/bind'

  import Button from './Button'
  import css from './style.scss'

  const cN = classNames.bind(css)

  export let config

  const { label, title, titleTag, paragraphs, button, withTopMargin, withBottomMargin } = config

  function fillTemplate(template, replacers) {
    if (replacers === undefined) {
      return [template]
    }
    return Object.keys(replacers).reduce(
      (parts, key) => {
        return parts
          .map((part) => {
            const split = part.split(key)
            const splitPartsLength = split.length

            for (let idx = splitPartsLength - 1; idx > 0; idx--) {
              split.splice(idx, 0, key)
            }

            return split
          })
          .flat(Infinity)
      },
      [template]
    )
  }
</script>

<WidgetHeader withMargin={withTopMargin} />
<GridContainer class={cN('texts')}>
  <GridColumn widthMiddle={4} class={cN('texts__title-block')}>
    {#if label !== undefined}
      <ActionText size={ActionText.SIZES.SMALL} class={cN('texts__label')}>{label}</ActionText>
      <Separator key={5} size={Separator.SIZES.SMALL} />
    {/if}

    <Header tag={titleTag}>{title}</Header>

    {#if button !== undefined}
      <div class={cN('texts__button--for-not-mobile')}>
        <Button text={button.text} href={button.href} class={cN('texts__button')} />
      </div>
    {/if}
  </GridColumn>
  <GridColumn widthMiddle={8}>
    {#each paragraphs as { title, text: content, replacers }, idx}
      {#if idx > 0}
        <Separator key={title !== undefined ? 20 : 10} size={Separator.SIZES.MEDIUM} />
      {/if}
      <div class={cN('texts__paragraph')}>
        {#if title !== undefined}
          <ActionText class={cN('texts__paragraph-title')}>{title}</ActionText>
          <Separator key={5} size={Separator.SIZES.SMALL} />
        {/if}

        <Text size={Text.SIZES.LARGE}>
          {#each fillTemplate(content, replacers) as part, idx}
            {#if idx % 2 === 0}
              {part}
            {:else}
              <Link target="_blank" rank={Link.RANKS.THEME_PRIMARY} backdropColor={Link.BACKDROPS.LIGHT} href={replacers[part].href}>
                {replacers[part].text}
              </Link>
            {/if}
          {/each}
        </Text>
<!--        <ContentHandler baseText={{ Component: Text, props: { size: 'large' } }} {content} />-->
      </div>
    {/each}
  </GridColumn>
  {#if button !== undefined}
    <GridColumn class={cN('texts__button', 'texts__button--for-mobile')}>
      <Button text={button.text} href={button.href} />
    </GridColumn>
  {/if}
</GridContainer>
<WidgetFooter withMargin={withBottomMargin} />
